@import 'styles/variables.scss';

$video-section-height: calc(100vh - #{$controls-height} - #{$navbar-height} - 3rem);
$video-section-width: calc(#{$video-section-height} * 16 / 9);
$video-section-height-no-controls: calc(100vh - #{$navbar-height} - 2rem);
$video-section-width-no-controls: calc(#{$video-section-height-no-controls} * 16 / 9);

$mobile-screen-height: calc(100vh - calc(100vh - 100%)); // Fix to handle search bars on iOS
$mobile-video-section-height: calc(#{$mobile-screen-height} - #{$navbar-height});
$mobile-main-video-container-height: calc(#{$mobile-video-section-height} - 2rem);

.main-page > .container-fluid,
.main-page > .container-fluid > .row,
.main-page > .container-fluid > .row > .col {
  height: 100%;
}

@media (max-width: $responsive-breakpoint-medium) {
  .main-page > .container-fluid {
    padding-top: $navbar-height !important;
  }
}

.main-page {
  height: 100% !important;
  text-align: center;

  .navbar {
    .call-status {
      margin: 0 auto;
      height: calc(#{$navbar-height} - 20px);
      min-width: 100px;
      width: 30%;
      display: flex;

      .navbar-info {
        flex: 1;
        font-size: 18px;
        justify-content: center;
        align-items: center;

        &.warning {
          background-color: $color-warning;
          border-color: $color-warning;
        }

        &.STARTING,
        &.FINISHED,
        &.HUNG_UP {
          background-color: $color-gray-02;
          border-color: $color-gray-02;
        }

        &.LIVE {
          background-color: $color-black;
          border-color: $color-black;
          color: white;
        }
        .status {
          font-weight: normal;
        }
      }
    }

    .btn.icon {
      &.btn-primary:not(:disabled):not(.disabled):active,
      &.btn-primary:not(:disabled):not(.disabled).active {
        background-color: $color-primary;
      }
      &.basic:hover:not(:disabled):not(.disabled),
      &.basic.hover:not(:disabled):not(.disabled) {
        background-color: $color-gray-06 !important;
      }
      i.aw-icon {
        font-size: 1.5rem;
      }
    }

    .aw-navbar-side:last-child {
      flex: 0 0 auto;
    }

    .navbar-nav.right {
      margin-left: 0;
    }

    @media (max-width: $responsive-breakpoint-medium) {
      height: $navbar-height;

      .call-status {
        flex: 1 1 auto;
      }

      #mute-remote-audio,
      #mute-audio,
      #mute-video,
      #leave-call {
        display: none;
      }

      #switch-language {
        margin-left: 1rem;
      }
    }
  }

  .main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .video-section {
    flex: 0 0 auto;
    height: $video-section-height;
    width: $video-section-width;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: width linear 0.3s;

    &.no-controls {
      height: $video-section-height-no-controls;
      width: $video-section-width-no-controls;
    }

    &:not(.online) {
      display: none;
    }

    .remote-stream-section,
    .local-stream-section {
      max-height: 100%;
      position: relative;
      margin: 0 0.5rem;
      transition: width linear 0.3s;
    }

    .remote-stream,
    .local-stream {
      background-color: black;
    }

    .video-container {
      width: 100%;
      padding-top: 56.25%;
      height: 0px;
      position: relative;

      .guest-name,
      .host-name {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        font-size: 1rem;
        z-index: 1;
        .status {
          font-weight: normal;
        }
      }
    }

    video {
      border-radius: $border-radius;
      width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .vu-meter-container {
      position: absolute;
      right: 10px;
      bottom: 10px;
      height: 150px;
      max-height: calc(100% - 20px);

      & + .vu-meter-container {
        right: 40px;
      }
    }

    .remote-stream-section .video-camera-off {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      i {
        font-size: 6vh;
        transition: font-size 0.3s;
      }
    }

    .hidden {
      display: none;
    }

    &.ONLY_REMOTE {
      .remote-stream-section {
        width: 100%;
        .video-camera-off i {
          font-size: 10vh;
        }
      }
      .local-stream-section {
        width: 0%;
        .vu-meter-container,
        .guest-name {
          display: none;
        }
      }
    }
    &.REMOTE_BIG {
      width: calc(#{$video-section-width} * 1.4);
      .remote-stream-section {
        width: 70%;
        .video-camera-off i {
          font-size: 10vh;
        }
      }
      .local-stream-section {
        width: 30%;
      }
    }

    &.EQUAL {
      width: calc(#{$video-section-width} * 2);
      .remote-stream-section {
        width: 50%;
      }
      .local-stream-section {
        width: 50%;
      }
    }
    &.LOCAL_BIG {
      width: calc(#{$video-section-width} * 1.4);
      .remote-stream-section {
        width: 30%;
      }
      .local-stream-section {
        width: 70%;
      }
    }
    &.ONLY_LOCAL {
      .remote-stream-section {
        width: 0%;
        .vu-meter-container,
        .video-camera-off,
        .host-name {
          display: none;
        }
      }
      .local-stream-section {
        width: 100%;
        margin: 0;
      }

      @media (max-width: $responsive-breakpoint-medium) {
        .local-stream-section {
          height: 100%;

          .video-container {
            height: 100%;
            width: 100%;
            padding-top: 0 !important;
          }
        }
      }
    }

    &.OVERLAY {
      .local-stream-section {
        position: absolute;
        top: 2rem;
        left: 1rem;
        z-index: 1;
        width: 33vw;
        height: auto;
        margin: 0;

        .video-container {
          padding-top: 0;
          height: auto;

          video {
            position: relative;
            height: auto;
            box-shadow: 0px 0px 4px 4px rgba(255, 255, 255, 0.3);
            border-radius: $border-radius;
          }
        }
      }

      .remote-stream-section {
        height: 100%;
        width: 100%;
        margin: 0;

        .video-container {
          height: 100%;
          width: 100%;
          padding-top: 0 !important;
        }
      }

      // For very small devices
      @media (max-width: $responsive-breakpoint-small) {
        .guest-name {
          font-size: 0.6rem;
          left: 0.6rem;
        }

        .vu-meter-container > .vu-meter {
          height: 100%;
        }
      }
    }

    @media (max-width: $responsive-breakpoint-medium) {
      height: $mobile-video-section-height !important;
      width: 100% !important;
      padding: 1rem 0;

      &.no-controls {
        height: $mobile-video-section-height !important;
        width: 100% !important;
      }
    }
  }

  .bye-message {
    position: absolute;
    top: 45%;
    z-index: 1;
    h2 {
      margin-bottom: $spacer;
    }
  }

  .controls {
    display: flex;
    flex: 1 1 auto;

    .mobile-actions {
      display: none;
    }

    @media (max-width: $responsive-breakpoint-medium) {
      flex: 0 0 auto;
      justify-self: end;

      .layout-selector {
        display: none;
      }

      .mobile-actions {
        flex: 0 0 auto;
        display: flex;
        width: 100vw;
        height: $navbar-height;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: $color-dark-bg-box;
      }
    }
  }

  .error {
    color: $danger;
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .aw-icon {
      font-size: 2rem;
    }
  }
}

@media (max-width: $responsive-breakpoint-medium) {
  .aw-modal > .aw-modal-content {
    height: calc(#{$mobile-screen-height} - 60px);
  }
}

.settings-modal {
  .help-layout {
    .form-section {
      .scroll {
        // To avoid double overflow scroll, we only rely on 'modal' overflow
        height: auto !important;
        max-height: none !important;
      }
    }
  }

  .video-container {
    position: relative;
    margin-bottom: 1rem;
    //min-height: 100px;
    width: 100%;
    padding-top: 56.25%;
    height: 0px;
    background: black;
    border-radius: $border-radius;

    .vu-meter-container {
      position: absolute;
      right: 10px;
      bottom: 16px;
      height: 150px;
      max-height: 80%;
    }

    video {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;
      pointer-events: none;
      border-radius: $border-radius;
    }
  }

  .error-details {
    font-size: 0.8rem;
    color: $color-gray-01;
    padding-top: calc($spacer / 2);
  }

  .warning,
  .error {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 1rem;
    i {
      font-size: 3rem;
      margin: $spacer;
    }
  }

  .warning {
    color: $color-warning;
  }

  .error {
    color: $danger;
  }
}

.testing-modal {
  margin: $spacer 0;

  .test-status {
    padding: $spacer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .aw-icon {
      font-size: 3rem;
    }
    .text {
      margin-top: $spacer;
    }
  }
  .list-group {
    .list-group-item {
      min-height: 70px;
    }
    .icon {
      text-align: end;
      font-size: 1.5rem;
      .aw-icon.aw-close_circle {
        color: $danger;
      }
      .aw-icon.aw-warning {
        color: $warning;
      }
    }
  }
  .buttons {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @media (max-width: $responsive-breakpoint-medium) {
    margin-top: 0;
  }
}

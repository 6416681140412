$mobile-screen-height: calc(100vh - calc(100vh - 100%)); // Fix to handle search bars on iOS

#auth-body > .content > div {
  text-align: center;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .aw-icon {
    font-size: 4rem;
  }

  .title {
    font-size: 1.25rem;
  }

  .aw-loader {
    display: block;
  }

  .password {
    height: 50%;
    display: flex;
    flex-direction: column-reverse;
    max-width: 300px;
    width: 100%;
    text-align: left;

    .alert {
      max-width: 300px;
    }

    input {
      max-width: 300px;
      display: inline-block;

      &::placeholder {
        color: $haiui-gray-06;
      }
    }
  }

  .controls {
    display: flex;
    align-items: stretch;
    justify-content: center;

    .btn {
      margin: 1rem;
    }
  }

  .error {
    color: $haiui-red-01;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .aw-icon {
      font-size: 2rem;
    }
  }

  @media (max-width: $responsive-breakpoint-small) {
    min-height: 0;

    .title {
      padding: 2 * $spacer;
    }
  }
}

@media (max-width: $responsive-breakpoint-medium) {
  #auth-layout {
    height: $mobile-screen-height;
  }
}

#auth-body > .content {
  position: relative;

  .version {
    position: absolute;
    display: block;
    text-align: center;
    bottom: 0;
    left: 0;
    right: 0;
    color: $color-dark-text-secondary;
    height: 30px;
  }
}

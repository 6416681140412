.languages {
  list-style: none;
  padding-left: 0;

  .language {
    font-size: 1.2rem;
    padding: 0.5rem;
    cursor: pointer;

    &:hover {
      color: $color-primary;
    }
  }
}

@import 'components/Call/Call.scss';
@import 'components/Check/Check.scss';
@import 'components/LanguageSelector/LanguageSelector.scss';
@import 'components/LayoutSelector/LayoutSelector.scss';
@import 'components/LiveguestLogo/LiveguestLogo.scss';
@import 'components/SoundMeter/SoundMeter.scss';

@import './variables.scss';

html,
body,
#root {
  height: 100%;
}

#auth-background > .baseline {
  max-width: 400px;
  text-align: end;
}

input.aw-theme {
  $color: fade_out($primary, 0.5);

  &.form-control:focus {
    border-color: $secondary;
    box-shadow: 0 0 0 2px $color;
    -webkit-box-shadow: 0 0 0 2px $color;
    outline: -webkit-focus-ring-color auto 0;
  }
}

.aw-modal .aw-modal-title > .content {
  line-height: 1.5;
}
